// import logo from './logo.svg';
import './App.css';
import React from "react";

const AppNav = () => (
  <nav class="navbar navbar-dark bg-dark">
    <a class="navbar-brand" href='#'>My Social Media Application</a>
  </nav>
);

const Card = ({ item, handleSubmit, handleCancel }) => {
  const { title, content, username, editMode } = item;

  if (editMode) {
    return (
      <div class="card mt-4" Style="width: 100%;">
        <div class="card-body">
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={item.id} />
            <div class="input-group input-group-sm mb-3">
              <input type="text" name="title" class="form-control" placeholder="Title" defaultValue={title} />
            </div>
            <div class="input-group input-group-sm mb-3">
              <textarea name="content" class="form-control" placeholder="Content" defaultValue={content}></textarea>
            </div>
            <div class="input-group input-group-sm mb-3">
              <input type="text" name="username" class="form-control" placeholder="Username" defaultValue={username} />
            </div>
            <button type="button" class="btn btn-outline-secondary btn-sm" onClick={handleCancel}>Cancel</button>
            <button type="submit" class="btn btn-info btn-sm ml-2">Save</button>
          </form>
        </div>
      </div>
    )
  } else {
    return (
      <div class="card mt-4" Style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">{title}</h5>
          <p class="card-text">{content}</p>
          <p class="card-text">{username}</p>
        </div>
      </div>
    )
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    this.getPosts();
  }

  getPosts = async () => {
    try {
      const response = await fetch('https://social-media-worker.salmanmunaf.workers.dev/');
      const data = await response.json();
      data.forEach(item => item.editMode = false);
      this.setState({ data });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  addNewPost = () => {
    const data = this.state.data;
    data.unshift({
      editMode: true,
      title: "",
      content: "",
      username: ""
    })
    this.setState({ data })
  }

  handleCancel = async () => {
    await this.getPosts();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const body = JSON.stringify({
      title: data.get('title'),
      content: data.get('content'),
      username: data.get('username')
    });

    const headers = {
      'content-type': 'application/json',
      accept: 'application/json',
    };

    await fetch('https://social-media-worker.salmanmunaf.workers.dev/', {
      mode: 'no-cors',
      method: 'POST',
      headers,
      body,
    });
    await this.getPosts();
  }

  render() {
    return (
      <div>
        <AppNav />
        <button type="button" class="mt-4 mb-2 btn btn-primary btn-sm float-right" onClick={this.addNewPost}>
          Add New Post
        </button>
        {
          this.state.data.length > 0 ? (
            this.state.data.map(item =>
              <Card item={item}
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
              />)
          ) : (
            <div class="card mt-5 col-sm">
              <div class="card-body">You don't have any posts. Use the "Add New Post" button to add some new posts!</div>
            </div>
          )
        }
      </div>
    );
  }
}

export default App;
